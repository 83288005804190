/*
 * Author: dizhong zhu
 * Date: 14/08/2024
 *
 * This file maintains the setting for the whole session, not saving anything in cookie or database yet, will do in future
 * Only assume you do that without refresh the page. If you refresh the page, the setting will be lost
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

/************************************************************************************************
 *
 *                                Types and Interfaces
 *
 ************************************************************************************************/

// Define all setting keys as constants
export const STORAGE_KEYS = {
    SHOW_ACTIVE_TENANTS: 'showActiveTenants',
    SELECTED_DOMAIN: 'selectedDomain',
} as const

interface SettingState {
    showActiveTenants: boolean
    selectedDomain: string
    // Add other settings here
}

// Function to load initial state from localStorage
const loadInitialState = (): SettingState => {
    if (typeof window === 'undefined') {
        return {
            showActiveTenants: false,
            selectedDomain: '',
        }
    }

    return {
        showActiveTenants: localStorage.getItem(STORAGE_KEYS.SHOW_ACTIVE_TENANTS) === 'true',
        selectedDomain: localStorage.getItem(STORAGE_KEYS.SELECTED_DOMAIN) || '',
    }
}

export const initialState: SettingState = loadInitialState()
/************************************************************************************************
 *
 *                                          Functions
 *
 ************************************************************************************************/
export const SettingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        setShowActiveTenants: (state, { payload }: PayloadAction<boolean>) => {
            state.showActiveTenants = payload
            localStorage.setItem(STORAGE_KEYS.SHOW_ACTIVE_TENANTS, payload.toString())
        },
        setSelectedDomain: (state, { payload }: PayloadAction<string>) => {
            state.selectedDomain = payload
            localStorage.setItem(STORAGE_KEYS.SELECTED_DOMAIN, payload)
        },
    },
})

export const { setShowActiveTenants, setSelectedDomain } = SettingSlice.actions
export default SettingSlice.reducer
